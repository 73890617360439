import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formState: {},
  errorState: {},
  icOption: [],
  modelOption: [],
  manufacturerOption: [],
  manufacturerId: null,
  rtoData: [],
  leadDetails: {},
};

const reduxFormManage = createSlice({
  name: "reduxFromManage",
  initialState,
  reducers: {
    ICData: (state, action) => {
      state.icOption = action.payload;
    },
    ModelData: (state, action) => {
      state.modelOption = action.payload;
    },
    ManufacturerData: (state, action) => {
      state.manufacturerOption = action.payload;
    },
    manufacturerId: (state, action) => {
      state.manufacturerId = action.payload;
    },
    RtoData: (state, action) => {
      state.rtoData = action.payload;
    },
    EditLeadDetails: (state, action) => {
      state.leadDetails = action.payload;
      console.log("action.payload", action.payload);
    },
    setFormManagerAsPerKey(state, action) {
      const { type, key, value } = action?.payload;
      const data = {
        [type]: {
          ...state.leadDetails[type],
          [key]: value,
        },
      };
      state.leadDetails = { ...state.leadDetails, ...data };
    },
  },
});

export const {
  ICData,
  ModelData,
  ManufacturerData,
  manufacturerId,
  RtoData,
  EditLeadDetails,
  setFormManagerAsPerKey,
} = reduxFormManage.actions;
export default reduxFormManage.reducer;
