import React, { useCallback } from "react";
// eslint-disable-next-line no-unused-vars
import _ from "lodash";
import "./CustomInput.scss";

function CustomInput({
  type = "text",
  viral = false,
  placeholder,
  name,
  value = "",
  onChange,
  label,
  readonly,
  disabled,
  hideLabel,
  maxLength,
  register,
  error,
}) {
  // const handleChange = (event) => {
  //   let newValue = event.target.value;
  //   if (type == "number") {
  //     // Allow only non-negative numbers and decimals
  //     if (/^\d*\.?\d*$/.test(newValue)) {
  //       setText(newValue);

  //       validation(newValue);
  //     }
  //   } else {
  //     if (toUpperCase) {
  //       newValue = newValue.toUpperCase();
  //     }
  //     setText(newValue);
  //     debouncedHandlerRef.current(event);
  //     validation(newValue);
  //   }
  // };

  // useEffect(() => {
  //   setText(value);
  // }, [value]);
  return (
    <div className="custom-input-wrapper">
      {label && (
        <label htmlFor="customInput" className="form-label">
          {label}
        </label>
      )}
      {viral ? <span className="text-danger">*</span> : ""}

      <div className="input-with-prefix">
        <input
          type={type}
          name={name}
          className="form-control form-control-lg"
          placeholder={placeholder}
          value={value}
          readOnly={readonly}
          disabled={disabled}
          {...(register ? register : {})}
          onChange={(e) => {
            if (register) register.onChange(e); // Call react-hook-form onChange
            if (onChange) onChange(e); // Call custom onChange if provided
          }}
          maxLength={maxLength}
        />
      </div>

      {error && <div className="error-message-input">{error.message}</div>}
    </div>
  );
}

export default CustomInput;
