export const commonColor = {
    edoboRed: "rgb(211,47,47)",
    edoboRed1: "#CA2D2D",
    edoboRed2: "#D32F2F",
    secondryColor: "#edf1f7",
    edoboRedLight: "rgb(211,47,47, 0.1)",
    white: "#FFFFFF",
    black: "#000000",
    shadowBlack: "#00000a",
    gray: "#808080",
    lightGray: "#f1f1f1",
    graan: "#008000",
    graanLight: "rgba(16, 185, 129, 0.12)",
    textGreen: "rgb(11, 129, 90)",
    lightBlue: "rgba(6, 174, 212, 0.12)",
    blue: "#0000FF",
    lightTernary: "rgba(247, 144, 9, 0.12)",
    ternary: "rgb(181, 71, 8)",
  };


  
  